import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'initials',
  standalone: true,
})
export class InitialsPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return '';

    const parts = value.trim().split(' ');

    const initials = parts.slice(0, 2).map(part => part.charAt(0));

    return initials.join('');
  }
}

@Pipe({
  name: 'toArray',
  standalone: true,
})
export class ToArrayPipe implements PipeTransform {
  transform(value: number): number[] {
    return new Array(value).fill(0);
  }
}

@Pipe({
  standalone: true,
  name: 'hzConverter',
})
export class HzConverterPipe implements PipeTransform {
  transform(valueInHz: number | undefined, unit: 'MHz' | 'GHz'): string {
    let result = 0;
    if (valueInHz === undefined) return String(result);
    switch (unit) {
      case 'MHz':
        result = valueInHz / 1e6;
        break;
      case 'GHz':
        result = valueInHz / 1e9;
        break;
    }
    return result.toFixed(2);
  }
}
