import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  input,
  numberAttribute,
} from '@angular/core';
import { BadgeDirective } from './badge.directive';
import { ProgressComponent } from './progress.component';

@Component({
  selector: 'app-battery',
  standalone: true,
  imports: [ProgressComponent, BadgeDirective],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    @if (isBadge()) {
      @if (value() === 0) {
        <span appBadge class="bg-gray-100 text-gray-800">
          Batterie :
          {{ value() + '%' }}
        </span>
      } @else if (value() < 25) {
        <span appBadge class="bg-red-100 text-red-800">
          Batterie :
          {{ value() + '%' }}
        </span>
      } @else if (value() < 50) {
        <span appBadge class="bg-orange-100 text-orange-800">
          Batterie :
          {{ value() + '%' }}
        </span>
      } @else if (value() < 75) {
        <span appBadge class="bg-yellow-100 text-yellow-800">
          Batterie :
          {{ value() + '%' }}
        </span>
      } @else {
        <span appBadge class="bg-green-100 text-green-800">
          Batterie :
          {{ value() + '%' }}
        </span>
      }
    } @else {
      @if (value() === 0) {
        <app-progress
          [value]="value()"
          class="text-gray-800"
          progressColor="bg-gray-100" />
      } @else if (value() < 25) {
        <app-progress
          [value]="value()"
          class="text-red-800"
          progressColor="bg-red-200" />
      } @else if (value() < 50) {
        <app-progress
          [value]="value()"
          class="text-orange-800"
          progressColor="bg-orange-200" />
      } @else if (value() < 75) {
        <app-progress
          [value]="value()"
          class="text-yellow-800"
          progressColor="bg-yellow-200" />
      } @else {
        <app-progress
          [value]="value()"
          class="text-green-800"
          progressColor="bg-green-200" />
      }
    }
  `,
})
export class BatteryComponent {
  value = input.required({ transform: numberAttribute });
  isBadge = input(false, { transform: booleanAttribute });
}
