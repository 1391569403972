import { Directive, computed, input } from '@angular/core';
import { cva, type VariantProps } from 'class-variance-authority';
import type { ClassValue } from 'clsx';
import { mergeClasses } from 'src/app/shared/util';

export const cardFooterVariants = cva('flex p-3 lg:p-5 lg:pt-0 pt-0 gap-1', {
  variants: {
    direction: {
      row: 'flex-row items-center space-x-1.5',
      column: 'flex-col space-y-1.5',
    },
  },
  defaultVariants: {
    direction: 'row',
  },
});
export type CardFooterVariants = VariantProps<typeof cardFooterVariants>;

@Directive({
  selector: '[appCardFooter]',
  standalone: true,
  host: {
    '[class]': '_computedClass()',
  },
})
export class CardFooterDirective {
  readonly userClass = input<ClassValue>('', { alias: 'class' });

  protected _computedClass = computed(() =>
    mergeClasses(
      cardFooterVariants({ direction: this.direction() }),
      this.userClass()
    )
  );

  public readonly direction = input<CardFooterVariants['direction']>('row');
}
