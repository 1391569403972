import { NgClass, NgStyle } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  booleanAttribute,
  computed,
  input,
  model,
  numberAttribute,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { VariantProps, cva } from 'class-variance-authority';
import { ClassValue } from 'clsx';

import { mergeClasses } from 'src/app/shared/util';

export const progressVariants = cva(
  'inline-block size-full rounded-full  text-white bg-gray-100 transition-color duration-300 ease-in-out',
  {
    variants: {
      size: {
        default: 'h-4 text-[0.75em]',
        sm: 'h-3 text-[0.6em]',
        lg: 'h-5 text-sm',
      },
    },
    defaultVariants: {
      size: 'default',
    },
  }
);

type ProgressVariants = VariantProps<typeof progressVariants>;

@Component({
  selector: 'app-progress',
  standalone: true,
  imports: [NgStyle, NgClass],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ProgressComponent,
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div
      class="relative h-full min-w-7 rounded-full transition-all duration-300 ease-in-out"
      [ngStyle]="{ 'width.%': computedValue() }"
      [ngClass]="progressColor()">
      @if (withText()) {
        <div
          class="absolute inset-0 flex items-center justify-center font-semibold">
          {{ computedValue() }}%
        </div>
      }
    </div>
  `,
  host: {
    '[class]': '_computedClass()',
  },
})
export class ProgressComponent implements ControlValueAccessor {
  value = model(0);
  size = input<ProgressVariants['size']>('default');
  progressColor = input('bg-black');
  max = input(100, { transform: numberAttribute });
  withText = input(true, { transform: booleanAttribute });

  computedValue = computed(() =>
    Math.round(
      this.value() > this.max() ? this.max() : (this.value() / this.max()) * 100
    )
  );

  readonly userClass = input<ClassValue>('', { alias: 'class' });
  protected _computedClass = computed(() =>
    mergeClasses(progressVariants({ size: this.size() }), this.userClass())
  );

  // eslint-disable-next-line
  onChange = (value: any) => {};

  // eslint-disable-next-line
  onTouched = () => {};

  // eslint-disable-next-line
  writeValue(value: any): void {
    this.value.set(value);
  }

  // eslint-disable-next-line
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  // eslint-disable-next-line
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
