import { Pipe, PipeTransform } from '@angular/core';
import { Md5 } from 'ts-md5';

function arrayBufferToBase64(arrayBuffer: ArrayBuffer): string {
  const uint8Array = new Uint8Array(arrayBuffer);
  const binaryString = uint8Array.reduce(
    (acc, byte) => acc + String.fromCharCode(byte),
    ''
  );
  return btoa(binaryString);
}

export function getBase64EncodedFileData(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      const data = reader.result as ArrayBuffer; // FileReader gives us the ArrayBuffer
      const base64Encoded = arrayBufferToBase64(data); // convert ArrayBuffer to base64 string
      resolve(base64Encoded);
    };

    reader.onerror = error => {
      reject(error);
    };

    reader.readAsArrayBuffer(file);
  });
}

export function generateMd5(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    reader.onload = (event: any) => {
      const binary = event.target.result;
      const hash = Md5.hashAsciiStr(binary).toString();
      resolve(hash);
    };
    reader.onerror = error => {
      reject(error);
    };
    reader.readAsArrayBuffer(file);
  });
}

const convertByteToKbMb = (sizeInBytes: number | undefined): string => {
  if (sizeInBytes === undefined) {
    return '?';
  }
  const sizeInKb = sizeInBytes / 1024;
  if (sizeInKb < 1024) {
    return `${sizeInKb.toFixed(2)} KB`;
  } else {
    const sizeInMb = sizeInKb / 1024;
    return `${sizeInMb.toFixed(2)} MB`;
  }
};

@Pipe({
  name: 'byteToKbMb',
  standalone: true,
})
export class ByteToKbMbPipe implements PipeTransform {
  transform(sizeInBytes: number | undefined): string {
    return convertByteToKbMb(sizeInBytes);
  }
}
