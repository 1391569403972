import { Directive, input } from '@angular/core';
import { DialogComponent } from './dialog.component';

let idSequence = 0;

@Directive({
  selector: 'button[appDialogCloseFor]',
  standalone: true,
  host: {
    '[id]': 'id()',
    '(click)': 'hide()',
    'aria-haspopup': 'dialog',
    '[attr.aria-expanded]': 'this.dialog()?.isOpen()',
    '[attr.data-state]': 'this.dialog()?.isOpen()',
    '[attr.aria-controls]': 'idSequence',
  },
  exportAs: 'appDialogCloseFor',
})
export class DialogCloseDirective {
  readonly id = input(`dialog-hide-${idSequence++}`);

  dialog = input.required<DialogComponent | null>({
    alias: 'appDialogCloseFor',
  });

  hide() {
    this.dialog()?.hide();
  }
}
