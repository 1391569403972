import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { StatusEnum } from '@wheere/api-tracker';
import { BadgeDirective } from 'src/app/shared/ui';

@Component({
  selector: 'app-device-status',
  standalone: true,
  imports: [BadgeDirective],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    @switch (status()) {
      @case (StatusEnum.Online) {
        <div appBadge class="bg-green-100 uppercase text-green-800">Online</div>
      }
      @case (StatusEnum.Restarting) {
        <div appBadge class="bg-orange-100 uppercase text-orange-800">
          Restarting
        </div>
      }
      @case (StatusEnum.Offline) {
        <div appBadge class="bg-red-100 uppercase text-red-800">Offline</div>
      }
      @case (StatusEnum.Upgrading) {
        <div appBadge class="bg-yellow-100 uppercase text-yellow-800">
          Upgrading
        </div>
      }
      @default {
        <div appBadge class="bg-gray-100 uppercase text-gray-800">None</div>
      }
    }
  `,
})
export class DeviceStatusComponent {
  status = input.required<StatusEnum>();
  readonly StatusEnum = StatusEnum;
}
