import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslocoPipe } from '@jsverse/transloco';

@Component({
  selector: 'app-credit',
  standalone: true,
  imports: [TranslocoPipe],
  template: `<p class="text-center text-xs tracking-wide text-gray-400">
    © 2023 - {{ currentYear }} Wheere.io <br />
    {{ 'all-rights-reserved' | transloco }}
  </p>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreditComponent {
  currentYear = new Date().getFullYear();
}
