import { DOCUMENT } from '@angular/common';
import { inject } from '@angular/core';
import { CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { Role, UserStore } from 'src/app/shared/data';

export function userIsSuperAdmin(): CanActivateFn {
  return () => {
    const isSuperAdmin = inject(UserStore).isSuperAdmin();

    if (isSuperAdmin) {
      return true;
    }

    return inject(Router).parseUrl('/forbidden');
  };
}

export function userHasAnyRole(roles: Role | Role[]): CanActivateFn {
  return () => {
    const hasAnyRole = inject(UserStore).hasAnyRole(roles);

    if (hasAnyRole) {
      return true;
    }

    return inject(Router).parseUrl('/forbidden');
  };
}

export function authenticationGuard(): CanActivateFn {
  return (_, state: RouterStateSnapshot) => {
    const service = inject(KeycloakService);
    const window = inject(DOCUMENT).defaultView;

    if (service.isLoggedIn()) {
      return true;
    }

    service.login({
      redirectUri: window.location.origin + state.url,
    });

    return false;
  };
}
