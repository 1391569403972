import { NgIf } from '@angular/common';
import { computed, Directive, effect, inject, input } from '@angular/core';
import { Role, UserStore } from 'src/app/shared/data';

@Directive({
  selector: '[appHasRole], [appHasRoleIsSuperAdmin]',
  standalone: true,
  hostDirectives: [NgIf],
})
export class HasRoleDirective {
  appHasRole = input<Role | Role[]>();
  appHasRoleIsSuperAdmin = input<boolean>(false);

  #store = inject(UserStore);
  #ngIf = inject(NgIf, { host: true });

  private readonly showTemplate = computed(() => {
    if (!this.#store.isLoggedIn()) {
      return false;
    }

    if (this.appHasRoleIsSuperAdmin()) {
      return this.#store.isSuperAdmin();
    }

    return !!this.#store.hasAnyRole(this.appHasRole());
  });

  constructor() {
    effect(() => {
      this.#ngIf.ngIf = this.showTemplate();
    });
  }
}
