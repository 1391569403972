import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  model,
  signal,
} from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import {
  AbstractControl,
  FormBuilder,
  ReactiveFormsModule,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import dayjs from 'dayjs';
import { distinctUntilChanged, filter, merge, tap } from 'rxjs';
import { ButtonDirective } from './button.directive';
// eslint-disable-next-line @softarc/sheriff/dependency-rule
import { InputDirective } from './form';

type DurationUnit = 'hour' | 'day' | 'month' | 'year';

function endDateAfterStartDateValidator(
  startDateField: string,
  endDateField: string
): ValidatorFn {
  return (formGroup: AbstractControl): Record<string, unknown> | null => {
    const startDate = formGroup.get(startDateField)?.value;
    const endDate = formGroup.get(endDateField)?.value;

    if (!startDate || !endDate) {
      return null;
    }

    return endDate > startDate ? null : { endDateBeforeStartDate: true };
  };
}

@Component({
  selector: 'app-duration-tabs',
  standalone: true,
  imports: [ReactiveFormsModule, NgClass, InputDirective, ButtonDirective],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div
      class="flex w-full flex-col items-baseline justify-between gap-5 sm:flex-row">
      <ul class="flex flex-wrap gap-3">
        @for (duration of durationList; track index; let index = $index) {
          <li class="block">
            <button
              class="size-10 rounded border shadow transition-shadow hover:bg-gray-50 active:shadow-sm sm:size-12"
              [ngClass]="{
                'bg-gray-50 font-semibold text-primary-500':
                  durationIndex() === index,
              }"
              (click)="duration.action(); durationIndex.set($index)">
              {{ duration.label }}
            </button>
          </li>
        }
      </ul>

      <form
        [formGroup]="form"
        (ngSubmit)="onSend()"
        class="flex h-full w-full flex-wrap items-center justify-between gap-3 sm:w-auto sm:flex-nowrap">
        <input
          id="start"
          name="start"
          appInput
          type="datetime-local"
          formControlName="start"
          [max]="maxDate"
          class="h-full" />

        <input
          id="end"
          name="end"
          appInput
          formControlName="end"
          [max]="maxDate"
          type="datetime-local"
          class="h-full" />
        <button
          [disabled]="!form.valid || form.pristine || durationIndex() !== -1"
          type="submit"
          appBtn
          size="lg"
          class="w-full sm:w-auto">
          Send
        </button>
      </form>
    </div>
  `,
  host: {
    class: 'block',
  },
})
export class DurationTabsComponent {
  startDate = model.required<Date>();
  endDate = model.required<Date>();
  private date = computed(() => ({
    start: this.startDate(),
    end: this.endDate(),
  }));

  // TODO do dynamic based on startDate & endDate
  private number = signal<number>(1);
  private unit = signal<DurationUnit | undefined>('hour');
  durationIndex = signal(0);

  private readonly today = dayjs();
  readonly maxDate = this.today.format('YYYY-MM-DDTHH:mm');

  #fb = inject(FormBuilder);

  form = this.#fb.group(
    {
      start: ['', [Validators.required]],
      end: ['', [Validators.required]],
    },
    { validators: endDateAfterStartDateValidator('start', 'end') }
  );

  private _startDate = computed(() => {
    if (this.number() > 0 && !!this.unit()) {
      return dayjs().subtract(this.number(), this.unit()).toDate();
    }
    return null;
  });

  constructor() {
    const dateSortcutChange$ = toObservable(this._startDate).pipe(
      filter(Boolean),
      distinctUntilChanged(),
      tap(start => {
        this.startDate.set(start);

        const end = dayjs();
        this.endDate.set(end.toDate());

        this.form.patchValue(
          {
            start: dayjs(start).format('YYYY-MM-DDTHH:mm'),
            end: end.format('YYYY-MM-DDTHH:mm'),
          },
          { emitEvent: false }
        );
      })
    );

    const formChange$ = this.form.valueChanges.pipe(
      tap(() => this.durationIndex.set(-1))
    );

    const updateForm$ = toObservable(this.date).pipe(
      tap(({ start, end }) => {
        this.form.patchValue(
          {
            start: dayjs(start).format('YYYY-MM-DDTHH:mm'),
            end: dayjs(end).format('YYYY-MM-DDTHH:mm'),
          },
          { emitEvent: false }
        );
      })
    );

    merge(dateSortcutChange$, formChange$, updateForm$)
      .pipe(takeUntilDestroyed())
      .subscribe();
  }

  onSend() {
    if (this.form.valid) {
      const { start, end } = this.form.value;
      this.startDate.set(dayjs(start).toDate());
      this.endDate.set(dayjs(end).toDate());
      this.form.markAsPristine();
    }
  }

  readonly durationList = [
    {
      label: '1h',
      action: () => {
        this.unit.set('hour');
        this.number.set(1);
      },
    },
    {
      label: '1D',
      action: () => {
        this.unit.set('day');
        this.number.set(1);
      },
    },
    {
      label: '1W',
      action: () => {
        this.unit.set('day');
        this.number.set(7);
      },
    },
    {
      label: '1M',
      action: () => {
        this.unit.set('month');
        this.number.set(1);
      },
    },
    {
      label: '3M',
      action: () => {
        this.unit.set('month');
        this.number.set(3);
      },
    },
    {
      label: '6M',
      action: () => {
        this.unit.set('month');
        this.number.set(6);
      },
    },
  ];
}
