import { A11yModule } from '@angular/cdk/a11y';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CardModule } from 'src/app/shared/ui';
import { ButtonDirective } from './button.directive';

@Component({
  selector: 'app-confirmation-dialog',
  standalone: true,
  imports: [A11yModule, CardModule, ButtonDirective],
  template: `
    <div cdkTrapFocus appCard>
      <div appCardHeader>
        <h3 appCardTitle>{{ dialogData.title }}</h3>
        @if (dialogData?.description; as description) {
          <p appCardDescription>{{ description }}</p>
        }
      </div>

      <div appCardFooter class="flex justify-end">
        <button appBtn (click)="dialogRef.close(false)" variant="outline">
          No, cancel
        </button>
        <button appBtn (click)="dialogRef.close(true)" cdkFocusInitial>
          Yes, I'm sure
        </button>
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmationDialogComponent {
  dialogData: { title: string; description?: string } = inject(DIALOG_DATA);
  dialogRef = inject(DialogRef);

  constructor() {
    if (!this.dialogData?.title)
      throw Error('ConfirmationDialogComponent has no title!');
  }
}
