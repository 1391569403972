import { animate, style, transition, trigger } from '@angular/animations';

export const TooltipAnimation = trigger('TooltipAnimation', [
  transition(':enter', [
    style({ opacity: 0, transform: 'scale(0.8)' }),
    animate(
      '150ms cubic-bezier(0, 0, 0.2, 1)',
      style({ opacity: 1, transform: 'scale(1)' })
    ),
  ]),
  transition(':leave', [
    animate(
      '75ms cubic-bezier(0.4, 0, 1, 1)',
      style({ opacity: 0, transform: 'scale(0.8)' })
    ),
  ]),
]);
