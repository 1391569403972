import {
  Directive,
  ElementRef,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  Pipe,
  PipeTransform,
} from '@angular/core';

// NOTE css class 'flash' definition is in style.scss

@Directive({
  selector: '[appFlashOnChange]',
  standalone: true,
})
export class FlashOnChangeDirective implements OnChanges, OnDestroy {
  @Input() appFlashOnChange: unknown;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private timeoutId: any;

  private el = inject(ElementRef);

  ngOnChanges() {
    this.flashEffect();
  }

  private flashEffect() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
      this.el.nativeElement.classList.remove('flash');
      this.timeoutId = null;
    }

    this.el.nativeElement.classList.add('flash');
    this.timeoutId = setTimeout(() => {
      this.el.nativeElement.classList.remove('flash');
    }, 1000);
  }

  ngOnDestroy() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
      this.el.nativeElement.classList.remove('flash');
      this.timeoutId = null;
    }
  }
}

@Pipe({
  name: 'flashOnChange',
  standalone: true,
})
export class FlashOnChangePipe<T> implements PipeTransform, OnDestroy {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private timeoutId: any;

  private el = inject(ElementRef);

  transform(value: T): T {
    this.flashEffect();

    return value;
  }

  private flashEffect() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
      this.el.nativeElement.classList.remove('flash');
      this.timeoutId = null;
    }

    this.el.nativeElement.classList.add('flash');
    this.timeoutId = setTimeout(() => {
      this.el.nativeElement.classList.remove('flash');
    }, 1000);
  }

  ngOnDestroy() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
      this.el.nativeElement.classList.remove('flash');
      this.timeoutId = null;
    }
  }
}
