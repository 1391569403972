import { FocusMonitor } from '@angular/cdk/a11y';
import { ESCAPE } from '@angular/cdk/keycodes';
import {
  Overlay,
  OverlayPositionBuilder,
  OverlayRef,
  ScrollStrategy,
} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
  AfterViewInit,
  DestroyRef,
  Directive,
  ElementRef,
  Inject,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  TemplateRef,
  inject,
  numberAttribute,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { filter, fromEvent, merge, of, switchMap, tap } from 'rxjs';
import { OverlayPosition, getOverlayPositions } from 'src/app/shared/util';
import { TooltipComponent } from './tooltip.component';
import { TOOLTIP_CONFIG, TooltipConfig } from './tooltip.provider';

export type TooltipPosition = OverlayPosition;
export type TooltipMode = 'hover' | 'click';

@Directive({
  selector: '[appTooltip]',
  exportAs: 'appTooltip',
  standalone: true,
})
export class TooltipDirective implements OnInit, OnDestroy, AfterViewInit {
  @Input('appTooltip') text = '';
  @Input('appTooltipPosition') position: TooltipPosition = this.config.position;
  @Input('appTooltipWithCaret') withCaret = false;
  @Input('appTooltipMode') mode: TooltipMode = this.config.mode;

  @Input({ alias: 'appTooltipTemplate' })
  template: TemplateRef<unknown> | null = null;

  @Input({ alias: 'appTooltipTemplateCtx' })
  templateCtx: unknown;

  @Input({ alias: 'appTooltipPositionOffset', transform: numberAttribute })
  positionOffset = this.config.positionOffset;

  private overlay = inject(Overlay);
  private overlayPositionBuilder = inject(OverlayPositionBuilder);
  private elementRef = inject(ElementRef);
  private destroyRef = inject(DestroyRef);
  private ngZone = inject(NgZone);
  private focusMonitor = inject(FocusMonitor);
  private overlayRef!: OverlayRef;

  constructor(@Inject(TOOLTIP_CONFIG) private config: TooltipConfig) {}

  ngOnInit() {
    const positions = getOverlayPositions(this.position, this.positionOffset);

    const positionStrategy = this.overlayPositionBuilder
      .flexibleConnectedTo(this.elementRef)
      .withPositions(positions)
      .withFlexibleDimensions(false)
      .withViewportMargin(6);

    const scrollStrategy: ScrollStrategy = this.overlay.scrollStrategies.close({
      threshold: 20,
    });

    this.overlayRef = this.overlay.create({ positionStrategy, scrollStrategy });

    this.overlayRef
      .keydownEvents()
      .pipe(
        filter(e => e.keyCode === ESCAPE),
        tap(() => this.hide()),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();

    const hoverModeHostListener$ = of(this.mode).pipe(
      filter(() => this.mode === 'hover'),
      switchMap(() =>
        merge(
          fromEvent(this.elementRef.nativeElement, 'mouseenter').pipe(
            tap(() => this.show())
          ),
          fromEvent(this.elementRef.nativeElement, 'mouseleave').pipe(
            tap(() => this.hide())
          )
        )
      )
    );

    const clickModeHostListener$ = of(this.mode).pipe(
      switchMap(() =>
        fromEvent(this.elementRef.nativeElement, 'click').pipe(
          filter(() => this.mode === 'click'),
          tap(() => this.toggle())
        )
      )
    );

    merge(hoverModeHostListener$, clickModeHostListener$)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe();
  }

  ngAfterViewInit() {
    // NOTE: the focus monitor runs outside the Angular zone.
    this.focusMonitor
      .monitor(this.elementRef)
      .pipe(
        tap(origin => {
          if (!origin) {
            this.ngZone.run(() => this.hide());
          } else if (origin === 'keyboard') {
            this.ngZone.run(() => this.show());
          }
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.overlayRef?.dispose();
  }

  show() {
    if (!this.overlayRef?.hasAttached()) {
      const tooltipRef = this.overlayRef.attach(
        new ComponentPortal(TooltipComponent)
      );

      if (this.template) {
        tooltipRef.instance.template = this.template;
        if (this.templateCtx) {
          tooltipRef.instance.templateCtx = this.templateCtx;
        }
      } else {
        tooltipRef.instance.text = this.text;
      }

      tooltipRef.instance.position = this.position;
      tooltipRef.instance.withCaret = this.withCaret;
    }
  }

  hide() {
    if (this.overlayRef?.hasAttached()) {
      this.overlayRef.detach();
    }
  }

  toggle() {
    if (this.overlayRef?.hasAttached()) {
      this.hide();
    } else {
      this.show();
    }
  }
}
