export type Role =
  | 'SUPERADMIN'
  | 'ADMIN'
  | 'MAINTAINER'
  | 'READER'
  | 'GATEWAY_ADMIN'
  | 'GATEWAY_MAINTAINER'
  | 'GATEWAY_READER'
  | 'TRACKER_ADMIN'
  | 'TRACKER_MAINTAINER'
  | 'TRACKER_READER';

export const GATEWAY_ROLES: Role[] = [
  'GATEWAY_ADMIN',
  'GATEWAY_MAINTAINER',
  'GATEWAY_READER',
];
export const DEVICE_ROLES: Role[] = [
  'TRACKER_ADMIN',
  'TRACKER_MAINTAINER',
  'TRACKER_READER',
];
