import { CdkMenu, CdkMenuItem, CdkMenuTrigger } from '@angular/cdk/menu';
import { ConnectedPosition } from '@angular/cdk/overlay';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
} from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { TranslocoPipe } from '@jsverse/transloco';
import { NgIconComponent } from '@ng-icons/core';
import { FastSvgComponent } from '@push-based/ngx-fast-svg';
import { KeycloakService } from 'keycloak-angular';
import {
  domainColors,
  HasRoleDirective,
  MenuItem,
  MenuItemRoles,
  UserStore,
} from 'src/app/shared/data';
import { SlideFromLeftEnterLeave } from 'src/app/shared/util';
import { AvatarMenuComponent } from './avatar-menu.component';

@Component({
  selector: 'app-topnav',
  standalone: true,
  imports: [
    RouterLink,
    FastSvgComponent,
    AvatarMenuComponent,
    CdkMenu,
    CdkMenuItem,
    CdkMenuTrigger,
    RouterLink,
    RouterLinkActive,
    TranslocoPipe,
    NgIconComponent,
    HasRoleDirective,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [SlideFromLeftEnterLeave],
  template: `
    <nav
      class="fixed inset-x-0 z-30 border-b border-gray-200 bg-white py-2.5"
      style="height: var(--topnav-height)">
      <div class="mx-auto flex h-full items-center justify-between px-3">
        <div class="flex select-none items-center justify-between gap-x-2">
          <a routerLink="/" class="h-7 shrink-0 sm:h-8">
            <span class="sr-only">Wheere logo</span>

            <fast-svg
              name="w"
              [style.color]="color()"
              width="100%"
              height="100%" />
          </a>

          <button
            [disabled]="!menuItemsRoles()?.length"
            [cdkMenuTriggerFor]="menu"
            [cdkMenuPosition]="menuPosition"
            #cdkMenuTriggerFor="cdkMenuTriggerFor"
            type="button"
            class="truncate py-2 pr-2 font-mono text-xl font-medium disabled:cursor-default sm:text-2xl">
            <div class="flex items-center justify-between gap-1.5">
              {{ title() }}
              @if (menuItemsRoles()?.length) {
                @if (cdkMenuTriggerFor.isOpen()) {
                  <ng-icon name="iconoirNavArrowRight" />
                } @else {
                  <ng-icon name="iconoirNavArrowDown" />
                }
              }
            </div>
          </button>

          <ng-template #menu>
            @defer (when cdkMenuTriggerFor.isOpen()) {
              <div
                @SlideFromLeftEnterLeave
                cdkMenu
                class="w-44 divide-y divide-gray-100 rounded-lg border border-gray-100 bg-white shadow-md">
                <ul class="py-2 text-sm text-gray-700">
                  @for (item of menuItemsRoles(); track $index) {
                    <li *appHasRole="item.roles" cdkMenuItem>
                      <a
                        [routerLink]="item.path"
                        routerLinkActive="font-bold cursor-default hover:bg-white"
                        class="block px-4 py-2 hover:bg-gray-100">
                        {{ item.label | transloco }}
                      </a>
                    </li>
                  }
                </ul>
              </div>
            }
          </ng-template>
        </div>

        <app-avatar-menu
          [fullname]="fullname()"
          [email]="email()"
          [menuItems]="burgerMenuItems()"
          (logout)="onLogout()" />
      </div>
    </nav>
  `,
})
export class TopnavComponent {
  title = input.required();
  color = input(domainColors.devices);
  menuItemsRoles = input<MenuItemRoles[]>();
  burgerMenuItems = input<MenuItem[]>();

  #keycloak = inject(KeycloakService);
  #userStore = inject(UserStore);

  fullname = this.#userStore.fullname;
  email = this.#userStore.email;

  onLogout() {
    this.#keycloak.logout();
  }

  readonly menuPosition: ConnectedPosition[] = [
    {
      originX: 'end',
      originY: 'top',
      overlayX: 'start',
      overlayY: 'top',
      offsetY: 8,
    },
    {
      originX: 'center',
      originY: 'bottom',
      overlayX: 'center',
      overlayY: 'top',
      offsetY: 8,
    },
  ];
}
