import { Gateway } from '@wheere/api-gateway';
import { Tracker } from '@wheere/api-tracker';

export const sortedByStatusAndName = (entities: Gateway[] | Tracker[]) => {
  const statusOrder = [
    'UPGRADING',
    'RESTARTING',
    'ONLINE',
    'OFFLINE',
    null,
    undefined,
  ];

  return entities.toSorted((a, b) => {
    const statusA = a?.lastStatusHistory?.status;
    const statusB = b?.lastStatusHistory?.status;

    const statusComparison =
      statusOrder.indexOf(statusA) - statusOrder.indexOf(statusB);

    if (statusComparison !== 0) return statusComparison;

    const nameA = a?.name ?? null;
    const nameB = b?.name ?? null;

    if (nameA && nameB) {
      return nameA.localeCompare(nameB);
    }

    // If one of the names is null/undefined, move it to the bottom
    if (!nameA && nameB) return 1;
    if (nameA && !nameB) return -1;

    // If both are null/undefined, consider them equal
    return 0;
  });
};
