import {
  ApexAnnotations,
  ApexAxisChartSeries,
  ApexChart,
  ApexDataLabels,
  ApexFill,
  ApexLegend,
  ApexMarkers,
  ApexNoData,
  ApexStroke,
  ApexTitleSubtitle,
  ApexTooltip,
  ApexXAxis,
  ApexYAxis,
} from 'ng-apexcharts';

export interface ApexChartOptions {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  legend: ApexLegend;
  dataLabels: ApexDataLabels;
  markers: ApexMarkers;
  title: ApexTitleSubtitle;
  fill: ApexFill;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  tooltip: ApexTooltip;
  stroke: ApexStroke;
  annotations: ApexAnnotations;
  colors: string[];
  noData: ApexNoData;
}

export const chartCustomColors = [
  '#ED107D',
  '#5000cd',
  '#ffc800',
  '#23d2cd',
  '#500238',
  '#0f1473',
  '#7FFFD4',
  '#8c564b',
  '#5DA24B',
  '#FFC500',
  '#A817DC',
  '#5A0DAC',
  '#e377c2',
  '#936EDB',
  '#7f7f7f',
  '#bcbd22',
  '#F78620',
  '#4C6278',
  '#0728F7',
  '#DEB887',
  '#DC3545',
  '#16A8D8',
  '#5E784C',
  '#A2874B',
  '#e66545',
  '#A52A2A',
  '#0FE070',
  '#2ca02c',
  '#00FFFF',
  '#FF5600',
];

export const customColors = (value: string | number) => {
  if (typeof value === 'number') {
    return chartCustomColors[value];
  }

  return chartCustomColors[parseInt(value?.substring(1, value.length))];
};

export const defaultApexLineChartOptions = (group: string) => {
  const options: Pick<
    ApexChartOptions,
    | 'legend'
    | 'chart'
    | 'stroke'
    | 'noData'
    | 'dataLabels'
    | 'markers'
    | 'xaxis'
    | 'tooltip'
    | 'colors'
  > = {
    legend: {
      show: true,
      showForSingleSeries: true,
    },
    chart: {
      height: 300,
      type: 'line',
      animations: {
        enabled: false,
      },
      stacked: false,
      zoom: {
        type: 'x',
        enabled: true,
        autoScaleYaxis: true,
      },
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: false,
        },
      },
      group,
      id: group,
    },
    stroke: {
      curve: 'straight',
      width: 2,
    },
    noData: {
      text: 'Loading...',
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 0,
    },
    xaxis: {
      type: 'datetime',
      labels: {
        datetimeUTC: false,
      },
    },
    tooltip: {
      x: {
        format: 'MMM dd yyyy HH:mm:ss',
      },
    },
    colors: chartCustomColors,
  };

  return options;
};

export const calculateChartInterval = (durationDays: number): number => {
  if (durationDays <= 1) {
    return 5;
  }

  if (durationDays <= 90) {
    return 5 * durationDays;
  }

  if (durationDays <= 180) {
    return 4 * durationDays;
  }

  return 2 * durationDays;
};
