import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import { StatusEnum } from '@wheere/api-tracker';
import { BadgeDirective } from 'src/app/shared/ui';

@Component({
  selector: 'app-device-uptime',
  standalone: true,
  imports: [BadgeDirective],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    @switch (status()) {
      @case (StatusEnum.Online) {
        <div appBadge class="bg-green-100 uppercase text-green-800">
          {{ uptimeDisplay() }}
        </div>
      }
      @case (StatusEnum.Offline) {
        <div appBadge class="bg-red-100 uppercase text-red-800">
          {{ uptimeDisplay() }}
        </div>
      }
      @case (StatusEnum.Restarting) {
        <div appBadge class="bg-orange-100 uppercase text-orange-800">
          {{ uptimeDisplay() }}
        </div>
      }
      @case (StatusEnum.Upgrading) {
        <div appBadge class="bg-yellow-100 uppercase text-yellow-800">
          {{ uptimeDisplay() }}
        </div>
      }
      @default {
        <div appBadge class="bg-gray-100 uppercase text-gray-800">
          {{ uptimeDisplay() }}
        </div>
      }
    }
  `,
})
export class DeviceUptimeComponent {
  status = input.required<StatusEnum>();
  uptime = input.required<number>();

  readonly StatusEnum = StatusEnum;

  uptimeDisplay = computed(
    () => `UP TIME ${!this.uptime() ? '' : this.uptime()}`
  );
}
