import { withDevtools } from '@angular-architects/ngrx-toolkit';
import { computed } from '@angular/core';
import {
  patchState,
  signalStore,
  withComputed,
  withMethods,
  withState,
} from '@ngrx/signals';
import { KeycloakProfile } from 'keycloak-js';
import { Role } from './role.model';

export interface UserState {
  isSuperAdmin: boolean;
  roles: Role[];
  profile: KeycloakProfile | null;
}

const initialUserState: UserState = {
  isSuperAdmin: false,
  roles: [],
  profile: null,
};

export const UserStore = signalStore(
  { providedIn: 'root' },
  withDevtools('user'),
  withState(initialUserState),
  withMethods(store => {
    return {
      loadUser: async (payload: {
        profile: KeycloakProfile;
        roles: Role[];
      }) => {
        const { profile, roles } = payload;

        patchState(store, {
          profile,
          roles,
          isSuperAdmin: roles.includes('SUPERADMIN'),
        });
      },
      hasAnyRole: (roles: Role | Role[]) => {
        if (store.isSuperAdmin()) return true;

        const rolesList = Array.isArray(roles) ? roles : [roles];

        return (
          rolesList.length === 0 ||
          store.roles()?.some(r => rolesList.includes(r))
        );
      },
    };
  }),
  withComputed(store => {
    return {
      isLoggedIn: computed(() => !!store.profile()),
      isSuperAdmin: computed(() => store.isSuperAdmin()),
      fullname: computed(
        () => `${store.profile().firstName} ${store.profile().lastName || ''}`
      ),
      email: computed(() => store.profile().email),
    };
  })
);
