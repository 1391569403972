import clsx, { ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';

/**
 * Merges multiple CSS class names into a single string, resolving conflicts efficiently.
 *
 * This function uses `clsx` to conditionally construct className strings and `twMerge` to ensure
 * there are no style conflicts between Tailwind CSS classes.
 *
 * @param inputs - A variable number of class values to merge. These can be strings, arrays, or objects.
 * @returns string - A single string of merged Tailwind CSS class names.
 *
 * @example
 * // Combining static class names
 * const className = mergeTailwindClasses('btn', 'btn-primary');
 * // className: 'btn btn-primary'
 *
 * @example
 * // Combining class names conditionally
 * const isActive = true;
 * const className = mergeTailwindClasses('btn', 'btn-primary', isActive && 'btn-active');
 * // className: 'btn btn-primary btn-active'
 *
 * @example
 * // Combining class names with arrays and objects
 * const className = mergeTailwindClasses('btn', ['btn-primary', { 'btn-active': isActive }]);
 * // className: 'btn btn-primary btn-active'
 */
export function mergeClasses(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}
