import { A11yModule } from '@angular/cdk/a11y';
import { ESCAPE } from '@angular/cdk/keycodes';
import {
  Overlay,
  OverlayPositionBuilder,
  OverlayRef,
  ScrollStrategy,
} from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewContainerRef,
  inject,
  input,
  output,
  signal,
  viewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { filter, tap } from 'rxjs';
import { EnterBottom } from 'src/app/shared/util';

const DialogConfig = {
  backdropClass: 'bg-gray-900 bg-opacity-40',
};

type DialogType = 'alertdialog' | 'dialog';

@Component({
  selector: 'app-dialog-content',
  standalone: true,
  template: `<ng-content></ng-content>`,
  host: {
    class: 'outline-0',
    '[attr.tabindex]': '0',
    '[attr.role]': 'document',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogContentComponent {}

@Component({
  selector: 'app-dialog',
  standalone: true,
  exportAs: 'appDialog',
  imports: [DialogContentComponent, A11yModule],
  animations: [EnterBottom()],
  host: {
    '[attr.role]': 'type()',
  },
  template: `
    <ng-template #content>
      @defer (when isOpen()) {
        <app-dialog-content @EnterBottom cdkTrapFocus>
          <ng-content></ng-content>
        </app-dialog-content>
      }
    </ng-template>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogComponent implements OnInit, OnDestroy {
  type = input<DialogType>('dialog');
  closed = output<void>();

  templateContent =
    viewChild.required<TemplateRef<DialogContentComponent>>('content');

  isOpen = signal(false);

  private overlayRef: OverlayRef | undefined = undefined;
  private overlay = inject(Overlay);
  private viewContainerRef = inject(ViewContainerRef);
  private destroyRef = inject(DestroyRef);
  private overlayPositionBuilder = inject(OverlayPositionBuilder);

  ngOnInit() {
    const positionStrategy = this.overlayPositionBuilder
      .global()
      .centerHorizontally()
      .centerVertically();

    const scrollStrategy: ScrollStrategy =
      this.overlay.scrollStrategies.block();

    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy,
      hasBackdrop: true,
      backdropClass: DialogConfig.backdropClass
        .split(' ')
        .concat(this.type() === 'dialog' ? 'cursor-pointer' : ''),
    });

    if (this.type() === 'dialog') {
      this.overlayRef
        .backdropClick()
        .pipe(
          tap(() => this.hide()),
          takeUntilDestroyed(this.destroyRef)
        )
        .subscribe();

      this.overlayRef
        .keydownEvents()
        .pipe(
          filter(e => e.keyCode === ESCAPE),
          tap(() => this.hide()),
          takeUntilDestroyed(this.destroyRef)
        )
        .subscribe();
    }
  }

  ngOnDestroy() {
    this.overlayRef?.dispose();
  }

  show() {
    if (
      this.templateContent() &&
      this.overlayRef &&
      !this.overlayRef.hasAttached()
    ) {
      this.overlayRef.attach(
        new TemplatePortal(this.templateContent(), this.viewContainerRef)
      );
      this.isOpen.set(true);
    }
  }

  hide() {
    if (this.overlayRef?.hasAttached()) {
      this.overlayRef.detach();
      this.closed.emit();
      this.isOpen.set(false);
    }
  }
}
