import { A11yModule } from '@angular/cdk/a11y';
import {
  ChangeDetectionStrategy,
  Component,
  input,
  output,
  viewChild,
} from '@angular/core';
import {
  ButtonDirective,
  CardModule,
  DialogCloseDirective,
  DialogComponent,
  DialogOpenDirective,
} from 'src/app/shared/ui';

@Component({
  selector: 'app-alert-dialog',
  standalone: true,
  imports: [
    DialogComponent,
    DialogCloseDirective,
    DialogOpenDirective,
    CardModule,
    ButtonDirective,
    A11yModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <app-dialog #dialog>
      <div appCard class="w-80">
        <div appCardHeader>
          <h3 appCardTitle>{{ title() }}</h3>
          <p appCardDescription>{{ description() }}</p>
        </div>

        <div appCardFooter class="flex justify-end">
          <button appBtn [appDialogCloseFor]="dialog" variant="outline">
            Cancel
          </button>
          <button appBtn (click)="confirm.emit()" cdkFocusInitial>
            {{ confirmLabel() }}
          </button>
        </div>
      </div>
    </app-dialog>
  `,
  host: {
    class: 'block',
  },
})
export class AlertDialogComponent {
  title = input('Are you sure?');
  description = input('This action is irreversible.');
  confirmLabel = input('Yes, continue');
  confirm = output<void>();
  dialog = viewChild<DialogComponent>('dialog');
}
