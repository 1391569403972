import { Directive, computed, input } from '@angular/core';
import { cva, type VariantProps } from 'class-variance-authority';
import type { ClassValue } from 'clsx';
import { mergeClasses } from 'src/app/shared/util';

const inputVariants = cva(
  'block w-full bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:border-black focus:ring-1 focus:ring-black font-base text-sm shadow-inner ring-offset-2 file:border-0 file:text-gray-500 file:bg-transparent file:text-sm file:font-medium placeholder:text-gray-500 disabled:cursor-not-allowed disabled:opacity-50',
  {
    variants: {
      size: {
        default: 'h-10 py-3 px-4',
        sm: 'h-9 px-3',
        lg: 'h-12 px-4 text-lg placeholder:text-base',
      },
      variant: {
        default: '',
        textarea: 'h-auto py-4',
      },
      error: {
        auto: '[&.ng-invalid.ng-touched]:text-red-500 [&.ng-invalid.ng-touched]:border-red-500 [&.ng-invalid.ng-touched]:focus-visible:ring-red-500',
        true: 'text-red-500 border-red-500 focus:border-red-500 focus:ring-red-500',
      },
    },
    defaultVariants: {
      size: 'default',
      error: 'auto',
    },
  }
);

type InputVariants = VariantProps<typeof inputVariants>;

@Directive({
  selector: '[appInput]',
  standalone: true,
  host: {
    '[class]': '_computedClass()',
  },
})
export class InputDirective {
  variant = input<InputVariants['variant']>('default');
  size = input<InputVariants['size']>('default');
  error = input<InputVariants['error']>('auto');

  public readonly userClass = input<ClassValue>('', { alias: 'class' });
  protected _computedClass = computed(() =>
    mergeClasses(
      inputVariants({
        size: this.size(),
        variant: this.variant(), // NOTES : the order is important here!
        error: this.error(),
      }),
      this.userClass()
    )
  );
}
