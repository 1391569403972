import { Dialog } from '@angular/cdk/dialog';
import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  input,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgIconComponent } from '@ng-icons/core';
import { Firmware } from '@wheere/api-gateway';
import { filter, Observable, switchMap, take, tap } from 'rxjs';
import {
  ButtonDirective,
  CardModule,
  ConfirmationDialogComponent,
  DialogCloseDirective,
  DialogComponent,
  FirmwareListViewComponent,
} from 'src/app/shared/ui';

@Component({
  selector: 'app-firmware-list',
  standalone: true,
  imports: [
    NgIconComponent,
    DialogCloseDirective,
    ButtonDirective,
    CardModule,
    FirmwareListViewComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div
      appCard
      class="h-screen w-screen overflow-y-auto sm:h-auto sm:max-h-[80vh] sm:w-auto sm:min-w-[400px]">
      <div appCardHeader class="sticky top-0 z-20 size-full border-b bg-white">
        <h3 appCardTitle class="flex items-center justify-between gap-4">
          Select a firmware version
          <button
            appBtn
            variant="icon"
            [appDialogCloseFor]="dialog()"
            class="m-1">
            <ng-icon name="iconoirXmark" size="1.25rem" />
            <span class="sr-only">Close dialog</span>
          </button>
        </h3>
      </div>
      <div appCardContent class="p-0 sm:p-0 md:p-0 lg:p-0">
        <app-firmware-list-view
          [firmwares]="firmwares()"
          (change)="onFirmwareChange($event)"
          [loading]="loading()" />
      </div>
    </div>
  `,
})
export class FirmwareListComponent {
  firmwares = input.required<Firmware[]>();
  loading = input(false, { transform: booleanAttribute });
  dialog = input.required<DialogComponent>();
  updateFn = input.required<(firmware: Firmware) => Observable<void>>();

  #dialog = inject(Dialog);
  #destroyRef = inject(DestroyRef);

  async onFirmwareChange(firmware: Firmware) {
    const dialogRef = this.#dialog.open(ConfirmationDialogComponent, {
      autoFocus: 'dialog',
      minWidth: '400px',
      role: 'alertdialog',
      data: {
        title: 'Are you sure?',
        description:
          'This will change the version of the firmware for this tracker',
      },
    });

    dialogRef.closed
      .pipe(
        take(1),
        filter(Boolean), // yes continue
        switchMap(() => this.updateFn()(firmware)),
        tap(() => this.dialog().hide()),
        takeUntilDestroyed(this.#destroyRef)
      )
      .subscribe();
  }
}
