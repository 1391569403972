import { animate, style, transition, trigger } from '@angular/animations';

export const DropdownEnterLeave = trigger('DropdownEnterLeave', [
  transition(':enter', [
    style({
      transform: 'scale(0.95)',
      opacity: 0,
    }),
    animate(
      '100ms ease-out',
      style({
        transform: 'scale(1)',
        opacity: 1,
      })
    ),
  ]),
  transition(':leave', [
    style({
      transform: 'scale(1)',
      opacity: 1,
    }),
    animate(
      '75ms ease-in',
      style({
        transform: 'scale(0.95)',
        opacity: 0,
      })
    ),
  ]),
]);

export const SlideFromLeftEnterLeave = trigger('SlideFromLeftEnterLeave', [
  transition(':enter', [
    style({
      transform: 'translateX(-24px)',
      opacity: 0,
    }),
    animate(
      '100ms ease-out',
      style({
        transform: 'translateX(0px)',
        opacity: 1,
      })
    ),
  ]),
  transition(':leave', [
    style({
      transform: 'translateX(0px)',
      opacity: 1,
    }),
    animate(
      '75ms ease-in',
      style({
        transform: 'translateX(-24px)',
        opacity: 0,
      })
    ),
  ]),
]);

export const EnterBottom = (duration = 300, delay = 0) =>
  trigger('EnterBottom', [
    transition(':enter', [
      style({
        transform: 'translateY(30px)',
        opacity: 0,
      }),
      animate(
        `${duration}ms ${delay}ms ease-in-out`,
        style({
          transform: 'translateY(0px)',
          opacity: 1,
        })
      ),
    ]),
    transition(':leave', [
      style({
        transform: 'translateY(0px)',
        opacity: 1,
      }),
      animate(
        `${duration}ms ease-in-out`,
        style({
          transform: 'translateY(30px)',
          opacity: 0,
        })
      ),
    ]),
  ]);
