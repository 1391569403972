import { InjectionToken } from '@angular/core';
import { TooltipMode, TooltipPosition } from './tooltip.directive';

export interface TooltipConfig {
  position: TooltipPosition;
  mode: TooltipMode;
  positionOffset: number;
}

export const TOOLTIP_CONFIG = new InjectionToken<TooltipConfig>(
  'Tooltip config for the tooltip directive'
);

const defaultTooltipConfig: TooltipConfig = {
  position: 'top',
  mode: 'hover',
  positionOffset: 8,
};

export function provideTooltip(config?: Partial<TooltipConfig>) {
  const positionOffset = config?.positionOffset
    ? Math.abs(config.positionOffset)
    : defaultTooltipConfig.positionOffset;

  const merged = { ...defaultTooltipConfig, ...config, positionOffset };

  return {
    provide: TOOLTIP_CONFIG,
    useValue: merged,
  };
}
