import { HttpClient } from '@angular/common/http';
import { Injectable, inject, isDevMode } from '@angular/core';
import { Translation, TranslocoLoader } from '@jsverse/transloco';
import { catchError, of } from 'rxjs';
import { availableLocales, defaultLocale } from './i18n-locales';

@Injectable({ providedIn: 'root' })
class TranslocoHttpLoader implements TranslocoLoader {
  private http = inject(HttpClient);

  getTranslation(lang: string) {
    return this.http.get<Translation>(`/assets/i18n/${lang}.json`).pipe(
      catchError(error => {
        console.error('Error fetching i18n data:', error);

        return of(error);
      })
    );
  }
}

export const configTransloco = {
  config: {
    availableLangs: availableLocales,
    defaultLang: defaultLocale,
    reRenderOnLangChange: true,
    prodMode: !isDevMode(),
    failedRetries: 1,
    fallbackLang: defaultLocale,
    missingHandler: {
      useFallbackTranslation: true,
    },
    flatten: {
      aot: !isDevMode(),
    },
  },
  loader: TranslocoHttpLoader,
};
