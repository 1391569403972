import { EventSourcePolyfill } from 'event-source-polyfill';
import { Observable } from 'rxjs';
import { uuid } from './uuid.util';

export function rxEventSource(token: string, path: string) {
  return new Observable<EventSource>(subscriber => {
    const source = new EventSourcePolyfill(`${path}?clientUUID=${uuid()}`, {
      heartbeatTimeout: 3_600_000,
      headers: {
        authorization: `Bearer ${token}`,
      },
    }) as EventSource;

    subscriber.next(source);

    return () => {
      source.close();
      subscriber.complete();
    };
  });
}

/**
 * Creates an Observable that listens to a specific event on an EventSource.
 *
 * @template T The type of the data to be emitted by the Observable.
 * @param {EventSource} source The EventSource instance to listen on.
 * @param {string} eventName The name of the event to listen for.
 * @param {string} key The key of the entity returned by the server to extract from the event data.
 * @returns {Observable<T>} An Observable that emits data of type T when the specified event occurs.
 */
export function rxEventSourceListener<T>(
  source: EventSource,
  eventName: string,
  key: string
) {
  return new Observable<T>(subscriber => {
    const eventListener = (event: MessageEvent) => {
      const data = JSON.parse(event.data)[key] as T;
      // NOTES : comment below if you want to dev without sse data changes
      subscriber.next(data);
    };

    source.addEventListener(eventName, eventListener);

    return () => {
      source.removeEventListener(eventName, eventListener);
      subscriber.complete();
    };
  });
}
