import { Directive, computed, input } from '@angular/core';
import { cva, type VariantProps } from 'class-variance-authority';
import type { ClassValue } from 'clsx';
import { mergeClasses } from 'src/app/shared/util';

export const cardVariants = cva(
  'rounded-lg border border-gray-200 bg-white shadow-md overflow-hidden',
  {
    variants: {},
    defaultVariants: {},
  }
);
export type CardVariants = VariantProps<typeof cardVariants>;

@Directive({
  selector: '[appCard]',
  standalone: true,
  host: {
    '[class]': '_computedClass()',
  },
})
export class CardDirective {
  readonly userClass = input<ClassValue>('', { alias: 'class' });

  protected _computedClass = computed(() =>
    mergeClasses(cardVariants(), this.userClass())
  );
}
