import { ConnectedPosition } from '@angular/cdk/overlay';

export type OverlayPosition = 'top' | 'right' | 'bottom' | 'left';

const OverlayPositionRecord = (offset: number) => {
  const position: Record<OverlayPosition, ConnectedPosition> = {
    top: {
      originX: 'center',
      originY: 'top',
      overlayX: 'center',
      overlayY: 'bottom',
      offsetY: -offset,
    },
    right: {
      originX: 'end',
      originY: 'center',
      overlayX: 'start',
      overlayY: 'center',
      offsetX: offset,
    },
    bottom: {
      originX: 'center',
      originY: 'bottom',
      overlayX: 'center',
      overlayY: 'top',
      offsetY: offset,
    },
    left: {
      originX: 'start',
      originY: 'center',
      overlayX: 'end',
      overlayY: 'center',
      offsetX: -offset,
    },
  };

  return position;
};

const getConnectedOverlayPosition = (
  position: OverlayPosition,
  offset: number
) => OverlayPositionRecord(offset)[position];

const getConnectedOverlayPositionFallback = (
  position: OverlayPosition,
  offset: number
): ConnectedPosition => {
  if (position === 'top') {
    return getConnectedOverlayPosition('bottom', offset);
  }

  if (position === 'bottom') {
    return getConnectedOverlayPosition('top', offset);
  }

  if (position === 'left') {
    return getConnectedOverlayPosition('right', offset);
  }

  return getConnectedOverlayPosition('left', offset);
};

export const getOverlayPositions = (
  position: OverlayPosition,
  offset: number
) => {
  return [
    getConnectedOverlayPosition(position, offset),
    getConnectedOverlayPositionFallback(position, offset),
  ];
};
