import { Directive, computed, input } from '@angular/core';
import { cva, type VariantProps } from 'class-variance-authority';
import type { ClassValue } from 'clsx';
import { mergeClasses } from 'src/app/shared/util';

export const cardContentVariants = cva('p-3 lg:p-5 pt-0 lg:pt-0', {
  variants: {},
  defaultVariants: {},
});

export type CardContentVariants = VariantProps<typeof cardContentVariants>;

@Directive({
  selector: '[appCardContent]',
  standalone: true,
  host: {
    '[class]': '_computedClass()',
  },
})
export class CardContentDirective {
  readonly userClass = input<ClassValue>('', { alias: 'class' });

  protected _computedClass = computed(() =>
    mergeClasses(cardContentVariants(), this.userClass())
  );
}
