import { DatePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  booleanAttribute,
  input,
  output,
} from '@angular/core';
import { FastSvgComponent } from '@push-based/ngx-fast-svg';
import { Firmware } from '@wheere/api-tracker';
import { SkeletonComponent } from 'src/app/shared/ui';
import { ByteToKbMbPipe } from 'src/app/shared/util';
@Component({
  selector: 'app-firmware-list-view',
  standalone: true,
  imports: [DatePipe, ByteToKbMbPipe, SkeletonComponent, FastSvgComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    @if (loading()) {
      loading...
    } @else if (firmwares().length) {
      <ul class="divide-y divide-gray-200">
        @for (firmware of firmwares(); track firmware.filename) {
          <li class="w-full">
            <button
              [disabled]="!clickable()"
              (click)="onClick(firmware)"
              class="flex w-full items-center space-x-4 px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 disabled:cursor-default">
              <div
                class="flex w-full items-center space-x-4 px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                <div class="flex-shrink-0">
                  <div class="size-10">
                    <fast-svg
                      name="firmwareFile"
                      width="100%"
                      height="100%"
                      class="text-gray-900" />
                  </div>
                </div>
                <div class="min-w-0 flex-1 text-left">
                  <p class="truncate text-sm font-medium text-gray-900">
                    {{ firmware.filename }}
                  </p>
                  <p class="truncate text-sm text-gray-500">
                    Released on {{ firmware.lastModified | date: 'short'
                    }}<br />
                    Checksum: {{ firmware.md5 }}
                  </p>
                </div>
                <div
                  class="inline-flex items-center text-right text-base font-semibold text-gray-900">
                  {{ firmware.size | byteToKbMb }}
                </div>
              </div>
            </button>
          </li>
        }
      </ul>
    } @else {
      Firmware list is empty.
    }
  `,
})
export class FirmwareListViewComponent {
  firmwares = input.required<Firmware[]>();
  loading = input(false, { transform: booleanAttribute });
  clickable = input(true, { transform: booleanAttribute });

  change = output<Firmware>();

  onClick(firmware: Firmware) {
    if (this.clickable()) {
      this.change.emit(firmware);
    }
  }
}
