import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { TranslocoPipe } from '@jsverse/transloco';
import { FastSvgComponent } from '@push-based/ngx-fast-svg';

import { MenuItem } from 'src/app/shared/data/domains';
import { CreditComponent } from 'src/app/shared/ui';

@Component({
  selector: 'app-sidenav',
  standalone: true,
  imports: [
    RouterLink,
    RouterLinkActive,
    CreditComponent,
    TranslocoPipe,
    FastSvgComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <aside
      class="sticky top-0 z-20 hidden h-screen overflow-y-auto border-r border-gray-200 bg-white lg:block lg:w-64"
      style="padding-top: var(--topnav-height)"
      aria-label="Sidenav">
      <div class="relative size-full overflow-y-auto bg-white">
        <nav class="px-3 py-5">
          <ul class="space-y-2">
            @for (item of items(); track $index) {
              <li>
                <a
                  [routerLink]="'./' + item.path"
                  routerLinkActive="bg-gray-100/75 text-primary-500"
                  [routerLinkActiveOptions]="{ exact: true }"
                  topnav-height
                  class="group flex items-center space-x-3 rounded-lg p-2 text-base font-normal text-gray-900 hover:bg-gray-100">
                  @if (item?.icon; as icon) {
                    <div
                      routerLinkActive="text-primary-500 group-hover:text-primary-500"
                      [routerLinkActiveOptions]="{ exact: true }"
                      class="h-6 w-6 text-gray-400 transition duration-75 group-hover:text-gray-900">
                      <fast-svg [name]="icon" width="95%" height="95%" />
                    </div>
                  }
                  <span>{{ item.label | transloco }}</span>
                </a>
              </li>
            }
          </ul>
        </nav>
        <div class="absolute bottom-0 w-full px-3 pb-4 pt-6">
          <app-credit />
        </div>
      </div>
    </aside>
  `,
})
export class SidenavComponent {
  items = input.required<MenuItem[]>();
}
