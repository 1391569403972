import { DOCUMENT } from '@angular/common';
import { inject, Injectable, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { fromEvent, map, merge, startWith } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class InternetService {
  isOnline: Signal<boolean>;

  private window = inject(DOCUMENT).defaultView;

  constructor() {
    const on$ = fromEvent(this.window, 'online').pipe(map(() => true));
    const off$ = fromEvent(this.window, 'offline').pipe(map(() => false));

    this.isOnline = toSignal(merge(on$, off$).pipe(startWith(true)));
  }
}
