import { Directive, booleanAttribute, computed, input } from '@angular/core';
import { cva, type VariantProps } from 'class-variance-authority';
import type { ClassValue } from 'clsx';

import { mergeClasses } from 'src/app/shared/util';

export const badgeVariants = cva(
  'inline-flex items-center select-none rounded me-2 px-2.5 py-0.5 font-medium transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2',
  {
    variants: {
      size: {
        default: 'text-xs',
        lg: 'text-sm',
      },
      pills: { true: 'rounded-full', false: '' },
      static: { true: '', false: 'hover:bg-opacity-80' },
    },
    defaultVariants: {
      size: 'default',
      pills: false,
      static: true,
    },
  }
);

type BadgeVariants = VariantProps<typeof badgeVariants>;

@Directive({
  selector: '[appBadge]',
  standalone: true,
  host: {
    '[class]': '_computedClass()',
  },
})
export class BadgeDirective {
  readonly userClass = input<ClassValue>('', { alias: 'class' });

  readonly size = input<BadgeVariants['size']>('default');
  readonly pills = input(false, { transform: booleanAttribute });
  readonly static = input(true, { transform: booleanAttribute });

  protected _computedClass = computed(() =>
    mergeClasses(
      badgeVariants({
        size: this.size(),
        pills: this.pills(),
        static: this.static(),
      }),
      this.userClass()
    )
  );
}
