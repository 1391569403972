import { DEVICE_ROLES, GATEWAY_ROLES, Role } from './role.model';

export type Domain =
  | 'devices'
  | 'gateways'
  | 'sales'
  | 'sites'
  | 'users'
  | 'trackers';

export const domainList: Domain[] = [
  'devices',
  'gateways',
  'sales',
  'sites',
  'users',
  'trackers',
];

export interface MenuItem {
  label: string;
  path: string;
  icon?: string;
  color?: string;
}

export const domainColors = {
  devices: '#F7067D',
  gateways: '#FFC800',
  sales: '#107DED',
  sites: '#5000CD',
  users: '#23D2CD',
};

export type MenuItemRoles = MenuItem & { roles: Role[] };

export const domainMenuItemsShortcut: MenuItemRoles[] = [
  {
    label: 'home',
    path: '/home',
    roles: [],
  },
  {
    label: 'devices',
    path: '/devices',
    icon: 'module',
    color: domainColors.devices,
    roles: DEVICE_ROLES,
  },
  {
    label: 'gateways',
    path: '/gateways',
    icon: 'gateway',
    color: domainColors.gateways,
    roles: GATEWAY_ROLES,
  },
  // {
  //   label: 'sales',
  //   path: '/sales',
  //   icon: 'sale',
  //   color: domainColors.sales,
  //   roles: [],
  // },
  // {
  //   label: 'sites',
  //   path: '/sites',
  //   icon: 'site',
  //   color: domainColors.sites,
  //   roles: [],
  // },
  // {
  //   label: 'users',
  //   path: '/users',
  //   icon: 'user',
  //   color: domainColors.users,
  //   roles: [],
  // },
];
