import { StyleSpecification } from 'maplibre-gl';
import { environment } from 'src/environments';

// NOTE : go to https://cloud.maptiler.com/maps/ to grab new ones

export type MapStyle = string | StyleSpecification;
export type MapStyleType = 'street' | 'satellite';
export type MapStyleMap = Record<MapStyleType, MapStyle>;

export const mapStyleMap: MapStyleMap = {
  street: `https://api.maptiler.com/maps/streets-v2/style.json?key=${environment.maptiles}`,
  satellite: `https://api.maptiler.com/maps/satellite/style.json?key=${environment.maptiles}`,
};
