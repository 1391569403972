import {
  APP_INITIALIZER,
  EnvironmentProviders,
  importProvidersFrom,
} from '@angular/core';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { combineEnvironmentProviders } from 'src/app/shared/util';
import { environment } from 'src/environments';

function initializeKeycloak(keycloak: KeycloakService) {
  return async () => {
    await keycloak.init({
      config: {
        url: environment.keycloak.issuer,
        realm: environment.keycloak.realm,
        clientId: environment.keycloak.clientId,
      },
      loadUserProfileAtStartUp: true,
      initOptions: {
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri:
          window.location.origin + '/silent-check-sso.html',
      },
    });
  };
}

const keycloakInitializer = () => ({
  provide: APP_INITIALIZER,
  useFactory: initializeKeycloak,
  multi: true,
  deps: [KeycloakService],
});

export function provideKeycloak(): EnvironmentProviders {
  return combineEnvironmentProviders([
    importProvidersFrom([KeycloakAngularModule]),
    keycloakInitializer() as unknown as EnvironmentProviders,
  ]);
}
