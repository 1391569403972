import { Pipe, PipeTransform } from '@angular/core';

/**
 * Converts a number of seconds into a formatted time string (HH:MM:SS).
 *
 * @param {number} seconds - The total number of seconds to convert.
 * @returns {string} The formatted time string in HH:MM:SS format.
 */
function formatSecondsInTime(seconds: number) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = (seconds % 3600) % 60;

  const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;

  return formattedTime;
}

@Pipe({
  name: 'formatSecondsInTime',
  standalone: true,
})
export class FormatSecondsInTimePipe implements PipeTransform {
  /**
   * Transforms the given number of seconds into a formatted time string (HH:MM:SS).
   *
   * @param {number} value - The total number of seconds to convert.
   * @returns {string} The formatted time string in HH:MM:SS format.
   */
  transform(value: number): string {
    if (isNaN(value) || value < 0) {
      return '00:00:00';
    }

    return formatSecondsInTime(value);
  }
}
